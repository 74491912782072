<template>
  <v-container class="pt-8">
    <BackArrow />
    <v-container class="pb-0">
      <p class="main-title">Convites</p>
    </v-container>
    <v-form
      id="formcadastro"
      ref="formcadastro"
      @submit.prevent="salvar"
      :lazy-validation="true"
    >
    <span>
      <v-flex xs12 v-for="invitation in invitations" :key="invitations.id">
        <v-container>
          <generic-card style="padding: 10px" :title="invitation.financialGroupName || 'Empresa não informada'">
            <v-col style="padding: 0;" cols="6">
              <v-chip
                style="background: #CCE9F9; color: #5A8BB0;"
                x-small
              >
                {{ invitation.cpf | cpf }}
              </v-chip>
            </v-col>
            <div v-if="!invitation.connectCompanyConnection">
              <p style="font-size: 14px;" class="main-subtitle mb-0">
                Enviado por {{ invitation.userWhoSendInvitation }}
              </p>
              <p style="font-size: 14px;" class="main-strong-text text-truncate">
                Enviado em {{ moment(invitation.invitationDate).format('DD/MM/YYYY') }}
              </p>
            </div>

            <v-card-actions v-if="isStatusAtivo(invitation.status)" style="margin-top: 20px">
              <v-row class="pl-2" align="center" align-content="center" justify="center">
                <v-col style="padding: 0;" cols="2">
                  <pulseIcon :color="getStatusColor(invitation)" />
                </v-col>

                <v-col class="pl-0" style="padding: 0" cols="10">
                  <p class="mt-4 text-left status-text">
                    {{ getStatusTraducao(invitation) }}
                  </p>
                </v-col>
              </v-row>
              <v-spacer/>
              <generic-btn
                :btn-props="{ rounded: true, elevation: 0 }"
                color="#E9EBFD"
                :defaultTheme="false"
                v-if="invitation.connectCompanyConnection.status !== 'DISCONNECT'"
                style="width: 130px; font-size: 12px; font-weight: bold; color: var(--v-primary)"
                :on-click="() => disconnectFromCompany(invitation.connectCompanyConnection.id)"
              >
                Desconectar
              </generic-btn>
            </v-card-actions>
            <v-card-actions
              class="ml-2"
              v-if="!invitation.status || invitation.status === 'PENDING'"
              style="margin-top: 20px"
            >
              <v-row align="center" align-content="center" justify="center">
                <v-col style="padding: 0;" cols="2">
                  <pulseIcon :color="getStatusColor(invitation)" />
                </v-col>

                <v-col class="pr-0 pl-2" style="padding: 0" cols="10">
                  <p class="mt-4 text-left status-text">
                    {{ getStatusTraducao(invitation) }}
                  </p>
                </v-col>
              </v-row>
              <v-spacer />
              <generic-btn
                v-if="invitation.status !== 'REFUSED'"
                color="#E9EBFD"
                :defaultTheme="false"
                :btn-props="{ rounded: true, elevation: 0 }"
                style="width: 100px; font-size: 12px; font-weight: bold; color: var(--v-primary)"
                :on-click="() => acceptInvite(invitation.id)"
              >
                Aceitar
              </generic-btn>
              <generic-btn
                v-if="invitation.status !== 'REFUSED'"
                color="#E9EBFD"
                :defaultTheme="false"
                :btn-props="{ rounded: true, elevation: 0 }"
                style="width: 100px; font-size: 12px; font-weight: bold; color: var(--v-primary)"
                :on-click="() => refuseInvite(invitation.id)"
              >
                Recusar
              </generic-btn>
            </v-card-actions>
          </generic-card>
        </v-container>
      </v-flex>
    </span>
    </v-form>
  </v-container>
</template>

<script>
  import { mapMutations } from "vuex";
  import $util from "../../common/util";
  import { updateUser } from "../../common/securityserver";
  import { findAllInvitationsByUserEmail, requestConnectionWithCompany, disconnectFromCompany, acceptConnectionWithCompany, refuseConnectionWithCompany } from "../../common/euprotegido-service"
  import { existsBeneficiaryByCpf } from "../../common/contract-service"
  import ContractService from "../../common/ContractService";
  import genericCard from "./../../components/generic-card/index.vue";
  import pulseIcon from "../../components/pulse-icon/index.vue";
  import moment from "moment";
  import BackArrow from "../../components/covid/back-arrow/index.vue"

  export default {
    components: {
      genericCard,
      pulseIcon,
      BackArrow
    },
    data() {
      return {
        moment,
        fields: {
          cpf: "",
        },
        describe : "",
        rules: [],
        btnLoading: false,
        cpfRules:[
          v => !v || $util.validateCPF(v) || 'CPF inválido',
        ],
        invitations : [],
      };

    },
    mounted() {
      this.loadInvitations();
    },
    async created() {
      this._contractservice = new ContractService();

      await this.loadDescribe();
    },
    methods: {
      ...mapMutations(["showmsg", "loading", "showDefaultErrorMsg", "refreshReloadableKey"]),
      isStatusAtivo(connectCompany){
        return connectCompany === 'ACTIVE' ||
          connectCompany === 'ACCEPT'
      },
      getStatusColor({ status, connectCompanyConnection }){
        let traducao = 'green';

        if(connectCompanyConnection && connectCompanyConnection.status) {
          if (connectCompanyConnection.status === 'ACTIVE')
            traducao = 'green';
          else if (connectCompanyConnection.status === 'DISCONNECT')
            traducao = 'red';
        }else {
          if(status === 'ACTIVE')
            traducao = 'green';
          else if(status === 'PENDING')
            traducao = 'yellow';
          else if(status === 'INACTIVE')
            traducao = 'red';
          else if(status === 'ACCEPT')
            traducao = 'green';
          else if(status === 'REFUSED')
            traducao = 'red';
        }

        return traducao;
      },
      getStatusTraducao({ status, connectCompanyConnection }){
        let traducao = "";

        if(connectCompanyConnection && connectCompanyConnection.status) {
          if (connectCompanyConnection.status === 'ACTIVE')
            traducao = 'Ativo';
          else if (connectCompanyConnection.status === 'DISCONNECT')
            traducao = 'Desconectado';
        }else {
          if (status === 'ACTIVE')
            traducao = 'Ativo';
          else if (status === 'PENDING')
            traducao = 'Pendente';
          else if (status === 'INACTIVE')
            traducao = 'Inativo';
          else if (status === 'REFUSED')
            traducao = 'Recusado';
          else if (status === 'ACCEPT')
            traducao = 'Aceito'
        }

        return traducao;
      },
      async loadDescribe() {
        await this._contractservice.FindbyDescribe();
        this.describe = JSON.parse(sessionStorage.getItem("describe-contract"));
      },
      getdescribe(name, campo) {
        return this._contractservice.dsDescribe(this.describe, name, campo);
      },
      disconnectFromCompany(connectCompanyID){
        this.loading(true);
        disconnectFromCompany(connectCompanyID).then(res => {
          this.showmsg({ text: "Solicitação efetuada com sucesso!", type: "success" });
          this.loading(false);
          this.loadInvitations();
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
      },
      acceptInvite(connectCompanyID){
        this.loading(true);
        acceptConnectionWithCompany(connectCompanyID, this.$auth.user().id)
          .then(res => {
            this.showmsg({ text: "Solicitação efetuada com sucesso!", type: "success" });
            this.loading(false);
            this.loadInvitations();
          })
          .catch(err => {
            this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
            this.loading(false);
          })
      },
      refuseInvite(connectCompanyID){
        this.loading(true);
        refuseConnectionWithCompany(connectCompanyID, this.$auth.user().id)
          .then(res => {
            this.showmsg({ text: "Solicitação efetuada com sucesso!", type: "success" });
            this.loading(false);
            this.loadInvitations();
          })
          .catch(err => {
            this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
            this.loading(false);
          })
      },
      connectWithCompany(financialGroupID){
        this.loading(true);

        const data = {
          financialGroupID,
          cpf: this.$auth.user().cpf,
          userID : this.$auth.user().id
        }

        requestConnectionWithCompany(data).then( res => {
          this.connectCompanies.forEach(function(c) {
            if(c.financialGroup.id == financialGroupID){
              c.connectCompanyOrder = res.data;
              c.status = res.data.status;
            }
          });

          this.showmsg({ text: "Solicitação efetuada com sucesso!", type: "success" });
          this.loading(false);
        }).catch( err => {
          this.showmsg({ text: "Erro ao conectar com a empresa!", type: "error" });
          this.loading(false);
        })

      },
      loadInvitations(){
        this.loading(true);
        this.checkIfBeneficiaryExists();
        findAllInvitationsByUserEmail(this.$auth.user().email).then(res => {
          this.invitations = res.data;

          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
          this.loading(false);
        })
      },
      checkIfBeneficiaryExists(){
        existsBeneficiaryByCpf(this.$auth.user().cpf).then(res => {
          this.hasBeneficiary = (res.data == true);
        }).catch(err => {
          this.showmsg({ text: "Erro ao carregar informações!", type: "error" });
        })
      },
      salvar() {
        if (!this.$refs.formcadastro.validate()) {
          setTimeout(this.$refs.formcadastro.resetValidation, 3000);
          return;
        }

        let cpf = $util.removerPontuacaoCPF(this.fields.cpf);

        const user = {
          id : this.$auth.user().id,
          cpf
        }

        this.loading(true);

        updateUser(user).then(res => {
          this.$auth.setCPF(cpf);
          this.hasCPF = true;
          this.loadConnectCompanies();
          this.showmsg({ text: "Cadastro salvo!", type: "success" });
          this.loading(false);
        }).catch(err => {
          this.showmsg({ text: "Erro ao atualizar cadastro!", type: "error" });
          this.loading(false);
        })

      }
    }
  };
</script>
